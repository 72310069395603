:root {
    --main-font: "geomanist"
}

/* Agregando las fuentes al tema */
@font-face {
    font-family: "geomanist";
    src: url("https://cdn.shopify.com/s/files/1/0516/5478/7271/files/Geomanist-Regular.otf?v=1692730649");
    font-weight: normal;
    font-weight: 400;
    font-display: swap;
}

@font-face {
    font-family: "geomanist";
    src: url("https://cdn.shopify.com/s/files/1/0516/5478/7271/files/Geomanist-Book.otf?v=1699915492");
    font-weight: 500;
    font-display: swap;
}

@font-face {
    font-family: "geomanist";
    src: url("https://cdn.shopify.com/s/files/1/0516/5478/7271/files/Geomanist-Book.otf?v=1692730649");
    font-weight: 600;
    font-display: swap;
}


@font-face {
    font-family: "geomanist";
    src: url("https://cdn.shopify.com/s/files/1/0516/5478/7271/files/Geomanist-Book.otf?v=1699915492");
    font-weight: 700;
    font-display: swap;
}

@font-face {
    font-family: "geomanist";
    src: url("https://cdn.shopify.com/s/files/1/0516/5478/7271/files/Geomanist-Bold.otf?v=1692730649");
    font-weight: 900;
    font-display: swap;
}